.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(5px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.35);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
}

